import React, { useRef, useState, useEffect } from "react";
import './HomeScreen.css';
import firebaseApp from "../firebase";
import "firebase/compat/firestore";
import { serverTimestamp } from "firebase/firestore";
import logo from "../assets/Redground-oldlogo.png";
import cover from "../assets/mars.webm";

function HomeScreen() {
  const stickyRef = useRef();
  const db = firebaseApp.firestore();
  const [formStatus, setFormStatus] = useState(true);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmitAccess = async (e) => {
    e.preventDefault();
    await db.collection("visitor_data")
      .add({
        nombre: firstname,
        apellidos: lastname,
        email: email,
        fecha: Date.now(),
        timestamp: serverTimestamp(),
        id: (email.substring(0, 3)) + Date.now(),
      })
      .then(() => {
        /* alert("Hi " + JSON.stringify(firstname) + " we have received your data and we will notify you when your access is ready."); */
        setFormStatus(false);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const TextSwitcher = () => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const texts = [
      'Take advantage of the opportunity for exponential growth with plots backed by strategic investments and space infrastructure projects.',
      'Transform your Martian land into valuable assets that can be used as collateral to obtain goods and services in the real world.',
      'Be part of the future with our collaboration with SpaceX and Blue Origin to develop essential infrastructure on Mars.',
      'Trust in a transparent and secure system that uses blockchain technology to record all transactions and protect your investment.',
      'RedGround is like a sticker album, but instead of stickers, you collect land in space.',
    ];

    useEffect(() => {
      // Use setInterval to switch text every 5 seconds (5000 milliseconds)
      const interval = setInterval(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, 12000);

      // Clear the interval when the component unmounts
      return () => clearInterval(interval);
    });

    return (
      <div>
        <h2 className="Home-header__cover__typewrite__msg">{texts[currentTextIndex]}</h2>
      </div>
    );
  };

  return (
    <div className="Home">
      <header className="Home-header" >
        <div className="Home-header__cover">
          <video autoPlay loop muted
            style={{
              position: "relative",
              zIndex: "1",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              backgroundPosition: "center center",
            }}>
            <source src={cover} type="video/webm" />
          </video>

          <div style={{
            position: "absolute",
            zIndex: "2",
            bottom: 0,
            width: "100%",
            height: "100%",
          }}>
            {/* <h1 className="Home-header__cover__typewrite"  >
              <Typewriter
                  onInit={(typewriter) => {
                      typewriter
                          .typeString('RedGround is your digital space where you can own and trade extraterrestrial lands on Mars backed by blockchain technology.')
                          .callFunction(() => {
                          })
                          .pauseFor(12000)
                          .deleteAll()
                          .callFunction(() => {
                          })
                          .start();
                  }}
                  options={{
                      strings: ['In RedGround, you choose a spot on Mars and make it your own, as if it were your territory in a game.', 'It is like drawing on a map: you pick where on Mars you want -your piece- and you are all set.', 'Imagine having a map of Mars and being able to say, -This land is mine-, that is what RedGround does.', 'RedGround is like a sticker album, but instead of stickers, you collect land in space.'],
                      autoStart: true,
                      loop: true,
                      deleteSpeed: 0,
                  }}
              />
            </h1> */}

            <div>
              <h1 className="Home-header__cover__typewrite">Join the pioneers of the next space age and secure your place in history with a unique plot on Mars. Join the financial revolution of the future.</h1>
            </div>

            <TextSwitcher />
          </div>
        </div>

        {formStatus &&
          <div className="Home-header__form" ref={stickyRef}>
            <img
              src={logo}
              alt=''
            />

            <div className="Home-header__form__title" >
              <h2>Sign up for RedGround and secure your piece of Mars! We are building a new economy based on the allocation of land on Mars.</h2>
              <p>Supported by strategic investments and alliances with space leaders such as SpaceX and Blue Origin.</p>
              <span>Register your contact data here:</span>
            </div>


            <form className="Home-header__form__info" >
              <ul>
                <li>
                  <input type="text" placeholder='Firstname' value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                </li>

                <li>
                  <input type="text" placeholder='Lastname' value={lastname} onChange={(e) => setLastname(e.target.value)} />
                </li>

                <li>
                  <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                </li>

              </ul>
            </form>

            <div className="Home-header__form__info__paybutton">
              <button
                type='submit'
                onClick={handleSubmitAccess} >Send
              </button>
            </div>

            {/* <div style={{ textAlign: 'center', margin: '20px' }}>
              <a
                href="https://redground-proto.web.app"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                See the Demo
              </a>
            </div> */}

            <div className="Home-header__form__copyright">
              <p>2024 Redground LLC S. :: all rights reserved.</p>
            </div>
          </div>
        }

        {!formStatus &&
          <div className="Home-header__form__sent">
            <img
              src={logo}
              alt=''
            />

            <div >
              <h2>Great! We've received your data and we'll notify you when your early access is ready.</h2>
            </div>

            <div className="Home-header__form__copyright">
              <p>2024 Redground LLC S. :: all rights reserved.</p>
            </div>
          </div>
        }
      </header>
    </div>
  );
}

export default HomeScreen;
